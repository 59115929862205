<!--物业管理  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :clickable="false"
      :showToolBar="true"
      :columns="columns"
      @add="add"
      @reset="reset"
      @pop-confirm="popConfirm"
      @searchList="searchList"
      :searchForm="searchForm"
      :dataSourceApi="api.list"
      :toolActions="toolActions"
      :searchActions="searchActions"
      :dataSourceParams="dataSourceParams"
      :dynamicSlotNames="['satisfaction', 'core']"
      @action-column-click="actionColumnClick"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="satisfaction" style="display: flex; align-items: center">
        <a-input-number
          placeholder="请输入"
          :default-value="100"
          :min="0"
          :max="100"
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          v-model="leftSatisfactionInterval"
        />-<a-input-number
          placeholder="请输入"
          :default-value="100"
          :min="0"
          :max="100"
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          v-model="rightSatisfactionInterval"
        />
      </div>
      <div slot="core" style="display: flex; align-items: center">
        <a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="leftScoreInterval"
        />-<a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="rightScoreInterval"
        />
      </div>
      <div slot="state" slot-scope="{ record }">
        <a-button
          :class="{
            red: record.state === '0',
            green: record.state === '2',
            grey: record.state === '1',
          }"
        >
          {{
            record.state === "0"
              ? "红榜"
              : record.state === "1"
              ? "黑榜"
              : "无榜单"
          }}
        </a-button>
      </div>
    </ax-table>
    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="新增物业管理"
      :visible.sync="dialogAdd"
      width="30%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable" />
    </el-dialog>
    <!-- 编辑跳转弹窗 -->

    <addedit ref="edit" @refreshTable="refreshTable"></addedit>
  </div>
</template>
<script>
import api from "./api";
import axTable from "@/components/ax-table";
import addPage from "./addPage.vue";
import addedit from "./addedit.vue";

const state = [
  { label: "红榜", value: "0" },
  { label: "黑榜", value: "1" },
  { label: "无榜单", value: "2" },
];

const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "propertyName",
    options: { placeholder: "请输入物业名称" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "物业负责人",
    type: "",
    model: "head",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },

  {
    label: "居名满意度",
    type: "",
    model: "satisfaction",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "居民评分",
    type: "inputNumber",
    model: "core",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "榜单",
    type: "select",
    model: "state",
    options: { options: state, placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, addedit, axTable },
  data() {
    //这里存放数据
    return {
      api,
      leftSatisfactionInterval: 0,
      rightSatisfactionInterval: 0,
      leftScoreInterval: 0,
      rightScoreInterval: 0,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchList", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [{ name: "add", text: "新增" }],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "物业名称",
            dataIndex: "propertyName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "物业负责人",
            dataIndex: "head",
            ellipsis: true,
            width: 150,
          },
          {
            title: "物业联系电话",
            dataIndex: "phone",
            ellipsis: true,
            width: 300,
          },
          {
            title: "居民满意度",
            dataIndex: "satisfaction",
            ellipsis: true,
            width: 250,
          },
          {
            title: "居民评分",
            dataIndex: "core",
            ellipsis: true,
            width: 300,
          },
          {
            title: "榜单",
            dataIndex: "state",
            ellipsis: false,
            width: 120,
          },
        ],
        true,
        {
          width: 250,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#2A5CFF",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  link: true,
                  type: "#FA5959",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: {},
      visible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    leftSatisfactionInterval(newVal) {
      if (newVal >= this.rightSatisfactionInterval) {
        this.rightSatisfactionInterval = newVal + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    rightSatisfactionInterval(newVal) {
      if (newVal <= this.leftSatisfactionInterval) {
        this.rightSatisfactionInterval = this.leftSatisfactionInterval + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    leftScoreInterval(newVal) {
      if (newVal >= this.rightScoreInterval) {
        this.rightScoreInterval = newVal + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    rightScoreInterval(newVal) {
      if (newVal <= this.leftScoreInterval) {
        this.rightScoreInterval = this.leftScoreInterval + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
  },
  //方法集合
  methods: {
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    // 改造的search
    searchList(e) {
      const transformObject = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[value.length - 1] : value;
          return acc;
        }, {});
      };
      e.formValues = transformObject(e.formValues);
      e.formValues.leftSatisfactionInterval =
        this.leftSatisfactionInterval || undefined;
      e.formValues.rightSatisfactionInterval =
        this.rightSatisfactionInterval || undefined;
      e.formValues.leftScoreInterval = this.leftScoreInterval || undefined;
      e.formValues.rightScoreInterval = this.rightScoreInterval || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    reset(e) {
      this.leftSatisfactionInterval = undefined;
      this.rightSatisfactionInterval = undefined;
      this.leftScoreInterval = undefined;
      this.rightScoreInterval = undefined;
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //编辑
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      console.log(res);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.red {
  background-color: #ffeeea;
  color: #fa5959;
  border-radius: 4px;
  border: #ffeeea;
}
</style>