<!-- 新增物业 -->
<template>
  <div>
    <ax-form ref="formBox" :formBuilder="formBuilder"> </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>
<script>
import api from "./api";

const formList = [
  {
    label: "物业名称",
    type: "",
    model: "propertyName",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 50,
    },
    col: {
      span: 24,
    },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,50}$/,
        message: "项目名称不能为空",
      },
    ],
  },
  {
    label: "负责人",
    type: "",
    model: "head",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 20,
    },
    col: {
      span: 24,
    },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "服务内容不能为空",
      },
    ],
  },
  {
    label: "联系方式",
    type: "",
    model: "phone",
    options: {
      minRows: 4,
      maxRows: 4,
      placeholder: "请输入",
    },
    col: {
      span: 24,
    },
    rules: [
      {
        required: true,
        message: "联系方式不能为空",
        pattern: /^1[3-9]\d{9}$/,
      },
    ],
  },
];

export default {
  components: {},
  data() {
    return {
      api,
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        {
          layout: "horizontal",
          labelWidth: 125,
        },
        {
          col: {
            span: 8,
          },
        }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        try {
          const res = await api.add(value);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.$emit("closeDialog");
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}

.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}

.button2 {
  display: flex;
  justify-content: space-between;
}
</style>